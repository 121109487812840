import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject({ });
  currentDates = this.messageSource.asObservable();

  constructor() { }
  changeDates(dates: { data_inicio: Date; data_fim: Date  } ){
    this.messageSource.next(dates);
  }
}
