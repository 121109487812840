import { Component, OnInit, Input } from '@angular/core';
import { Disciplina } from '../../models/Disciplina';
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";

@Component({
  selector: 'app-disciplinas-list',
  templateUrl: './disciplinas-list.component.html',
  styleUrls: ['./disciplinas-list.component.css'],
})
export class DisciplinasListComponent implements OnInit {

  @Input() disciplinas: Disciplina[];
  @Input() disciplinasFormArray: FormArray;
  constructor() { }

  ngOnInit(): void {
  }

}
