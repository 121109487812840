import { Component, OnInit } from '@angular/core';
import { ContratoService } from '../services/contrato.service';
import { DesempenhoService } from './desempenho.service';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {ListboxModule} from 'primeng/listbox';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-desempenho',
  templateUrl: './desempenho.component.html',
  styleUrls: ['./desempenho.component.css']
})
export class DesempenhoComponent implements OnInit {
  contratosSelecionados: SelectItem[];
  contratos: Observable<Object>;//SelectItem[] = [];
  geradorPlanilhaForm = new FormGroup({})
  constructor(private contratoService: ContratoService,
    private desempenhoService: DesempenhoService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.contratos = this.contratoService.getContratos();
    this.geradorPlanilhaForm = this.formBuilder.group({
      'contrato': new FormControl(this.contrato, [
        Validators.required
      ]),
      'dataInicio': new FormControl(this.dataInicio, [
        Validators.required
      ]),
      'dataFim': new FormControl(this.dataFim, [
        Validators.required
      ]),
    })
  }

  gerarPlanilha() {
    this.desempenhoService.gerarPlanilha(this.buscarObjetoContrato(this.contrato.value), this.dataInicio.value, this.dataFim.value);
  }

  buscarObjetoContrato(contratoValue: number){
    let contrato_obj = { 'id_contrato' :  contratoValue};
    for(var i in this.contratos){
      if(this.contratos[i].value ==  contratoValue){
        contrato_obj['nome'] = this.contratos[i].label;
      }
    }

    return contrato_obj;
  }

  get contrato() { return this.geradorPlanilhaForm.get('contrato') }
  get dataInicio() { return this.geradorPlanilhaForm.get('dataInicio') }
  get dataFim() { return this.geradorPlanilhaForm.get('dataFim') }

}
