import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorStackComponent } from '@nuvem/primeng-components';
import { LoginSuccessComponent } from '@nuvem/angular-base';
import { InicioComponent } from './inicio/inicio.component';
import { DesempenhoComponent } from './desempenho/desempenho.component';
import { BaselinesListComponent } from './baseline/baselines-list/baselines-list.component';
import { BaselineAddComponent } from './baseline/baseline-add/baseline-add.component';
import { CalculosChartComponent } from './calculos/calculos-chart/calculos-chart.component';
import { CalculosSelectComponent } from './calculos/calculos-select/calculos-select.component';
import { AuthGuard } from '@nuvem/angular-base';

const routes: Routes = [
    { path: '', component: InicioComponent, canActivate: [AuthGuard] },
    { path: 'desempenho', component: DesempenhoComponent, canActivate: [AuthGuard] },
    { path: 'baselines', component: BaselinesListComponent, canActivate: [AuthGuard] },
    { path: 'error-stack', component: ErrorStackComponent, data: { breadcrumb: 'Diário de Erros'}},
    { path: 'login-success', component: LoginSuccessComponent },
    { path: 'baseline/create', component: BaselineAddComponent }, 
    { path: 'calculos/chart/:contrato/:dataInicio/:dataFim', component: CalculosChartComponent },
    { path: 'calculos/select', component: CalculosSelectComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
