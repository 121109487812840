import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Baseline } from  '../models/Baseline';
import { BaselineAddComponent } from './baseline-add/baseline-add.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Routes } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BaselineService {

  private BUSCAR_DISCIPLINAS: string = '/api/baseline/disciplinas';
  private CRIAR_BASELINE: string = '/api/baseline';
  private BUSCAR_BASELINES: string = '/api/baselines';
  constructor(private http: HttpClient) { }

  getDisciplinasPorPeriodo(dataInicio: Date, dataFim: Date){
    return this.http.get(`${this.BUSCAR_DISCIPLINAS}/${dataInicio}/${dataFim}`);
  }

  createBaseline(baseline: any){
    return this.http.post(`${this.CRIAR_BASELINE}`, baseline);
  }

  getBaselines():Observable<Baseline[]> {
    return this.http.get<Baseline[]>(`${this.BUSCAR_BASELINES}`);
  }
}
