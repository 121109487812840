import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculosChartComponent } from './calculos-chart/calculos-chart.component';
import {ChartModule} from 'primeng/chart';
import { CalculosSelectComponent } from './calculos-select/calculos-select.component';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CalculosChartComponent],
  imports: [
    CommonModule,
    ChartModule,
    DropdownModule,
    ReactiveFormsModule
  ]
})
export class CalculosModule { }
