import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DesempenhoService {

  private GERAR_PLANILHA: string = '/api/desempenho'
  constructor(private http: HttpClient) {  }

  public gerarPlanilha(contrato: any, dataInicio: Date, dataFim: Date) {
    console.log(contrato);
    console.log(dataInicio);
    console.log(dataFim);
    
    return this.http.get(`${this.GERAR_PLANILHA}/${contrato.id_contrato}/${dataInicio}/${dataFim}`, {responseType: 'arraybuffer'}
      ).subscribe(response => this.dowloadFile(response, "application/ms-excel",  dataInicio, dataFim));
  }

  dowloadFile(data: any, type: string, dataInicio: Date, dataFim: Date) {
    let blob = new Blob([data], { type: type }),
    anchor = document.createElement('a');
    anchor.download = `Baseline_Desempenho_${dataInicio}_${dataFim}.xlsx`;
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
    anchor.dataset.downloadurl = ['ms-excel', anchor.download, anchor.href].join(':');
    anchor.click();
  }
}
