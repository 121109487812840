import { Component, OnInit, Input } from '@angular/core';
import { Disciplina } from '../../models/Disciplina';
import { Limite } from '../../models/Limite';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-limite',
  templateUrl: './limites-edit.component.html',
  styleUrls: ['./limites-edit.component.css']
})
export class LimiteEditComponent implements OnInit {

  @Input() disciplina: Disciplina;
  @Input() limitesFormArray: FormArray;
  constructor() { }

  ngOnInit(): void {
  }

}
