import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {

  private BUSCA_CONTRATOS : string = '/api/contratos'

  constructor(private http : HttpClient) { }

  public getContratos(){
    return this.http.get(`${this.BUSCA_CONTRATOS}`)
  }

}
