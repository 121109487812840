import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { Baseline } from 'src/app/models/Baseline';
import { BaselineService } from '../baseline.service';
@Component({
  selector: 'app-baselines-list',
  templateUrl: './baselines-list.component.html',
  styleUrls: ['./baselines-list.component.css']
})
export class BaselinesListComponent implements OnInit {
  display: boolean = false;
  baselineForm = new FormGroup({});
  baselines:Baseline[] = [];

  constructor(private formBuilder: FormBuilder, private data: DataService, private router: Router, private baselineService: BaselineService) { }

  ngOnInit(): void {
    this.baselineForm = this.formBuilder.group({
      'dataInicio': new FormControl(this.dataInicio, [
        Validators.required
      ]),
      'dataFim' : new FormControl(this.dataFim, [
        Validators.required
      ])
    });
    this.baselineService.getBaselines().subscribe(data => this.baselines = data)
  }

  showDialog(){
    this.display = true;
  }

  iniciarCriacaoBaseline(){
    this.data.changeDates({ data_inicio: this.dataInicio.value, data_fim: this.dataFim.value } )
    this.router.navigate(['/baseline/create'])
  }


  get dataInicio() { return this.baselineForm.get('dataInicio') }
  get dataFim() { return this.baselineForm.get('dataFim') }
}
