import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { BaselineService } from '../baseline.service';
import { Disciplina } from '../../models/Disciplina';
import { trigger } from '@angular/animations';
import { fadeIn, fadeOut } from '../../utils/animations/fade-animations';
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";

@Component({
  selector: 'app-baseline-add',
  animations: [
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn())
  ],
  templateUrl: './baseline-add.component.html',
  styleUrls: ['./baseline-add.component.css']
})
export class BaselineAddComponent implements OnInit {

  dates: {};
  disciplinas: Disciplina[] = [];
  disciplinasGhosts = new Array(4);
  useSpinner = false;
  createBaselineForm: FormGroup;

  constructor(private baselineAddService: BaselineService, private data: DataService, private appComponent: AppComponent, private router: Router, private formBuilder: FormBuilder) {
    this.createBaselineForm = this.formBuilder.group({
      const_densidade_erros_prev: [''],
      data_inicio_dados: [''],
      data_fim_dados: [''],
      data_inicio_de_uso: [''],
      data_fim_de_uso: [''],
      multiplicador_densidade_erros_prev: [''],
      disciplinas: this.formBuilder.array([
      ])
    })
   }

  ngOnInit(): void {
    this.data.currentDates.subscribe(dates => {
      this.dates = dates
      this.createBaselineForm.get('data_inicio_dados').setValue(this.dates['data_inicio'])
      this.createBaselineForm.get('data_fim_dados').setValue(this.dates['data_fim'])
    });
    if (!Object.keys(this.dates).length) {
      this.router.navigate(['/baselines'])
    } else {
      this.getDisciplinas(this.dates);
      // this.closeSidebar();
    }
  }

  closeSidebar() {
    if (this.appComponent.menuActive) {
      setTimeout(() => { this.appComponent.onMenuButtonClick(new MouseEvent('click')); }, 300);
    }
  }

  cancel(){
    this.router.navigate(['/baselines']);
  }

  getDisciplinas(dates: any, useSpinner = false) {
    this.useSpinner = useSpinner;
    this.baselineAddService.getDisciplinasPorPeriodo(dates.data_inicio, dates.data_fim).subscribe(response => {
      for (var key in response) {
        this.disciplinas.push(response[key])
        this.addDisciplinaForm(response[key]);
        this.disciplinasGhosts = [];
      }
    });
  }

  createBaseline(){
    let baseline = this.createBaselineForm.value;
    this.baselineAddService.createBaseline(baseline).subscribe((response) => {
       if(response = true) this.router.navigate(['baselines'])
    });
  }

  get disciplinasForm(){
    return this.createBaselineForm.get('disciplinas') as FormArray;
  }

  addDisciplinaForm(disciplina: Disciplina){
    let limitesForm: FormArray = this.formBuilder.array([]);
    disciplina.limites.forEach((limite) => {
      limitesForm.push(
        this.formBuilder.group({
          id_subprocesso: [limite.id_subprocesso],
          subprocesso: [limite.subprocesso],
          id_subgrupo: [limite.id_subgrupo],
          subgrupo: [limite.subgrupo],
          id_disciplina: [limite.id_disciplina],
          limite_inf_prod: [limite.limite_inf_prod],
          limite_sup_prod: [limite.limite_sup_prod],
          media_prod: [limite.media_prod],
          limite_espec_prod: [limite.limite_espec_prod],
          limite_inf_qual: [limite.limite_inf_qual],
          limite_sup_qual: [limite.limite_sup_qual],
          media_qual: [limite.media_qual],
          limite_espec_qual: [limite.limite_espec_qual],
        })
      )
    })

    this.disciplinasForm.push(this.formBuilder.group({
      tipo: [disciplina.nome],
      limites: limitesForm,
    }));

  }
}
