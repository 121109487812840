import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResultadoCalculosSixPack } from '../models/ResultadoCalculosSixPack';
import { SubgrupoSubprocesso } from '../models/SubgrupoSubprocesso';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { DadosPesquisaCalculos } from '../models/DadosPesquisaCalculos';

@Injectable({
  providedIn: 'root'
})
export class CalculosService {
  
  private PLOT_CHART: string = '/api/calculos/chart';
  private SELECT_CHART: string = '/api/calculos/select'
  constructor(private http: HttpClient) { }

  public selectChart(contrato: any, dataInicio: Date, dataFim: Date) {
    return this.http.get(`${this.SELECT_CHART}/${contrato.id_contrato}/${dataInicio}/${dataFim}`, 
    {responseType: 'arraybuffer'}
      ).subscribe(response => console.log(response));
  }

  getCalculosChart(dadosPesquisa:DadosPesquisaCalculos):Observable<ResultadoCalculosSixPack>{
    return this.http.get<ResultadoCalculosSixPack>(`${this.PLOT_CHART}/${dadosPesquisa.contrato}/${dadosPesquisa.dataInicio}/${dadosPesquisa.dataFim}/${dadosPesquisa.subgrupo}/${dadosPesquisa.subprocesso}/${dadosPesquisa.departamento}`);
  }

  getSubgrupos(dadosPesquisa:DadosPesquisaCalculos):Observable<SelectItem[]>{
    return this.http.get<SelectItem[]>(`${this.PLOT_CHART}/${dadosPesquisa.contrato}/${dadosPesquisa.dataInicio}/${dadosPesquisa.dataFim}/${dadosPesquisa.departamento}`);
  }

  getSubprocessos(dadosPesquisa:DadosPesquisaCalculos):Observable<SelectItem[]>{
    return this.http.get<SelectItem[]>(`${this.PLOT_CHART}/${dadosPesquisa.contrato}/${dadosPesquisa.dataInicio}/${dadosPesquisa.dataFim}/${dadosPesquisa.departamento}/${dadosPesquisa.subgrupo}`);
  }

  getDepartamentos(dadosPesquisa:DadosPesquisaCalculos):Observable<SelectItem[]>{
    return this.http.get<SelectItem[]>(`${this.PLOT_CHART}/${dadosPesquisa.contrato}/${dadosPesquisa.dataInicio}/${dadosPesquisa.dataFim}`);
  }

}
