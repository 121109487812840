import { Component, OnInit } from '@angular/core';
import { DadosGrafico } from 'src/app/models/DadosGrafico';
import { ResultadoCalculosSixPack } from 'src/app/models/ResultadoCalculosSixPack';
import { CalculosService } from '../calculos.service';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DadosPesquisaCalculos } from 'src/app/models/DadosPesquisaCalculos';
@Component({
    selector: 'app-calculos-chart',
    templateUrl: './calculos-chart.component.html',
    styleUrls: ['./calculos-chart.component.css']
})


export class CalculosChartComponent implements OnInit {
    subgrupos: Observable<SelectItem[]>;
    subprocessos: Observable<SelectItem[]>;
    departamentos: Observable<SelectItem[]>;
    geradorGraficoForm = new FormGroup({})
    basicData: any;
    basicOptions: any;
    tituloGrafico: String;
    dadosGraficos: Array<DadosGrafico> = [];

    dadosPesquisa: DadosPesquisaCalculos;
    constructor(private calculosService: CalculosService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.geradorGraficoForm = this.formBuilder.group({
            'contrato': new FormControl({ value: this.contrato, disabled: true }, [
                Validators.required
            ]),
            'dataInicio': new FormControl({ value: this.dataInicio, disabled: true }, [
                Validators.required
            ]),
            'dataFim': new FormControl({ value: this.dataFim, disabled: true }, [
                Validators.required
            ]),
            'subgrupo': new FormControl({ value: this.subgrupo }, [
                Validators.required
            ]),
            'subprocesso': new FormControl({ value: this.subprocesso }, [
                Validators.required
            ]),
            'departamento': new FormControl({ value: this.departamento }, [
                Validators.required
            ])
        });
        this.route.params.subscribe(params =>
            this.carregaDados({
                contrato: params['contrato'],
                dataInicio: params['dataInicio'],
                dataFim: params['dataFim'],
                departamento: '',
                subgrupo: '',
                subprocesso: ''
            })
        );
    }

        desvPad_dentro: any;
        cp_dentro: any;
        cpk_dentro: any;
        ppm_dentro: any;
        desvPad_global: any;
        pp_global: any;
        ppk_global: any;
        cpm_global: any;
        ppm_global: any;

    get contrato() { return this.geradorGraficoForm.get('contrato') }
    get dataInicio() { return this.geradorGraficoForm.get('dataInicio') }
    get dataFim() { return this.geradorGraficoForm.get('dataFim') }
    get subgrupo() { return this.geradorGraficoForm.get('subgrupo') }
    get subprocesso() { return this.geradorGraficoForm.get('subprocesso') }
    get departamento() { return this.geradorGraficoForm.get('departamento') }

    getContratos() {
        this.calculosService.getCalculosChart(this.dadosPesquisa).subscribe((response: ResultadoCalculosSixPack) => {
            //se resposta é vazia: retornar
            if (Object.keys(response).length === 0) return;
            
            let chavesTarefa = response.chavesTarefa;
            let dadosProd = response.medidasProdutividade;
            let dadosQual = response.medidasQualidade;
            let limiteInferiorProd = response.licProdutividade;
            let limiteSuperiorProd = response.lscProdutividade;
            let limiteInferiorQual = response.licQualidade;
            let limiteSuperiorQual = response.lscQualidade;

            this.desvPad_dentro = response.desvPad_dentro;
            this.cp_dentro = response.cp_dentro;
            this.cpk_dentro = response.cpk_dentro;
            this.ppm_dentro = response.ppm_dentro;
            this.desvPad_global = response.desvPad_global;
            this.pp_global = response.pp_global;
            this.ppk_global = response.ppk_global;
            this.cpm_global = response.cpm_global;
            this.ppm_global = response.ppm_global;

            this.dadosGraficos = [];

            this.dadosGraficos.push({
                titulo: `${response.departamento} - ${response.subgrupo} - ${response.subprocesso} - Produtividade`,
                data: this.criaSecaoDados(dadosProd, limiteInferiorProd, limiteSuperiorProd, chavesTarefa),
                option: {}
            });
            this.dadosGraficos.push({
                titulo: `${response.departamento} - ${response.subgrupo} - ${response.subprocesso} - Qualidade`,
                data: this.criaSecaoDados(dadosQual, limiteInferiorQual, limiteSuperiorQual, chavesTarefa),
                option: {}
            });
        });  
    };


    carregaDados(dadosPesquisa: DadosPesquisaCalculos) {
        this.dadosPesquisa = dadosPesquisa;
        this.departamentos = this.calculosService.getDepartamentos(this.dadosPesquisa);
        this.departamentos.subscribe(response => {
            if (response.length > 0) {
                this.dadosPesquisa.departamento = response[0].value;
                this.subgrupos = this.calculosService.getSubgrupos(this.dadosPesquisa);
                this.atualizaSubgrupo();
    
                this.subgrupos.subscribe(response => {
                    if (response.length > 0) {
                        this.dadosPesquisa.subgrupo = response[0].value;
                        this.subprocessos = this.calculosService.getSubprocessos(this.dadosPesquisa);
                        this.atualizaSubprocesso();
                    }
                })
            }
        });

    }

    criaSecaoDados(medidas: Array<Number>, limiteInferior: Number, limiteSuperior: Number, chavesTarefa: Array<String>) {
        return {
            labels: chavesTarefa,
            type: 'line',
            datasets: [
                {
                    label: 'Medidas',
                    data: medidas,
                    tension: 0,
                    fill: false,
                    borderColor: '#9ded1b'
                },
                {
                    label: 'Limite Superior de Controle',
                    borderWidth: 2,
                    fill: false,
                    data: new Array(medidas.length).fill(limiteSuperior),
                    borderColor: '#1b66de',
                    pointRadius: 0
                },
                {
                    label: 'Limite Inferior de Controle',
                    data: new Array(medidas.length).fill(limiteInferior),
                    fill: false,
                    borderColor: '#edb51b',
                    pointRadius: 0
                }
            ]
        };
    }

    onChangeDepartamento(event) {
        this.dadosPesquisa.departamento = event.value;
        this.subgrupos =
            this.calculosService.getSubgrupos(this.dadosPesquisa);
        this.atualizaSubgrupo();
    }

    onChangeSubgrupo(event) {
        this.dadosPesquisa.subgrupo = event.value;
        this.subprocessos =
            this.calculosService.getSubprocessos(this.dadosPesquisa);
        this.atualizaSubprocesso();
    }

    onChangeSubprocesso(event) {
        this.dadosPesquisa.subprocesso = event.value;
        this.getContratos();
    }


    atualizaSubprocesso() {
        this.subprocessos.subscribe(response => {
            if (response.length > 0) {
                this.dadosPesquisa.subprocesso = response[0].value;
                this.getContratos();
            }
        });
    }

    atualizaSubgrupo() {
        this.subgrupos.subscribe(response => {
            if (response.length > 0) {
                this.dadosPesquisa.subgrupo = response[0].value;
                this.getContratos();
            }
        });
    }

}
