import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CalculosService } from '../calculos.service';
import { DesempenhoService } from 'src/app/desempenho/desempenho.service';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContratoService } from 'src/app/services/contrato.service';

@Component({
  selector: 'app-calculos-select',
  templateUrl: './calculos-select.component.html',
  styleUrls: ['./calculos-select.component.css']
})
export class CalculosSelectComponent implements OnInit, OnChanges {
  contratos: Observable<Object>
  subgrupos: Observable<SelectItem>;
  geradorGraficoForm = new FormGroup({})
  constructor(private calculosService: CalculosService,
    private contratoService: ContratoService,
    private formBuilder: FormBuilder,
    private router: Router) { }
  
  ngOnChanges(changes:SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit(): void {
    this.contratos = this.contratoService.getContratos();
    this.geradorGraficoForm = this.formBuilder.group({
      'contrato': new FormControl(this.contrato, [
        Validators.required
      ]),
      'dataInicio': new FormControl(this.dataInicio, [
        Validators.required
      ]),
      'dataFim': new FormControl(this.dataFim, [
        Validators.required
      ]),
      'subgrupo': new FormControl(this.subgrupo, [
        Validators.required
      ])
    })
  }

  get contrato() { return this.geradorGraficoForm.get('contrato') }
  get dataInicio() { return this.geradorGraficoForm.get('dataInicio') }
  get dataFim() { return this.geradorGraficoForm.get('dataFim') }
  get subgrupo() { return this.geradorGraficoForm.get('subgrupo')}

  buscarObjetoContrato(contratoValue: number){
    let contrato_obj = { 'id_contrato' :  contratoValue};
    for(var i in this.contratos){
      if(this.contratos[i].value ==  contratoValue){
        contrato_obj['nome'] = this.contratos[i].label;
      }
    
      return contrato_obj;
    }
  }

  selectChart() {
    this.router.navigate(['calculos/chart', this.contrato.value.toString(), this.dataInicio.value, this.dataFim.value]);
    }

}
