import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppTopbarComponent } from './components/topbar/app.topbar.component';
import { AppFooterComponent } from './components/footer/app.footer.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { PageNotificationModule, BreadcrumbModule, MenuModule, ErrorStackModule, DatatableModule } from '@nuvem/primeng-components';
import { SecurityModule, VersionTagModule } from '@nuvem/angular-base';
import { InicioComponent } from './inicio/inicio.component';
import { DesempenhoComponent } from './desempenho/desempenho.component';
import { BaselinesListComponent } from './baseline/baselines-list/baselines-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { BaselineAddComponent } from './baseline/baseline-add/baseline-add.component';
import { LimiteEditComponent } from './baseline/limites-edit/limites-edit.component';
import { DisciplinasListComponent } from './baseline/disciplinas-list/disciplinas-list.component';
import { DisciplinasListGhostComponent } from './baseline/disciplinas-list-ghost/disciplinas-list-ghost.component';
import { CalculosModule } from './calculos/calculos.module';
import { CalculosSelectComponent } from './calculos/calculos-select/calculos-select.component';


@NgModule({
    declarations: [
        AppComponent,
        AppTopbarComponent,
        AppFooterComponent,
        InicioComponent,
        DesempenhoComponent,
        BaselinesListComponent,
        BaselineAddComponent,
        LimiteEditComponent,
        DisciplinasListComponent,
        DisciplinasListGhostComponent,
        CalculosSelectComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        PageNotificationModule,
        BreadcrumbModule,
        ErrorStackModule,
        VersionTagModule.forRoot(environment),
        SecurityModule.forRoot(environment.auth),
        MenuModule,
        DatatableModule,
        DialogModule, 
        CalculosModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
