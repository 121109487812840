import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-disciplinas-list-ghost',
  template:
    `
      <p-accordion *ngFor="let disciplina of disciplinas">
      <p-accordionTab header="...">  
      </p-accordionTab>
      </p-accordion>
    `
  ,
})
export class DisciplinasListGhostComponent implements OnInit {

  @Input() disciplinas: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
